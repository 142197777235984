import {mapActions, mapGetters, mapMutations} from "vuex";

import mainButton from '@/components/main-button/index.vue';
import mainSelect from '@/components/main-select/index.vue';

import {validationMixin} from 'vuelidate'
import {maxLength, minLength, required, email, sameAs} from "vuelidate/lib/validators";
//sections
export default {
  name: "profile-info",
  mixins: [validationMixin],
  watch: {
    '$route'(newVal){
      if(newVal){
        this.fetchUserData()
      }
    }
  },
  components:{
    mainButton,
    mainSelect
  },
  data(){
    return{
      payload:{
        first_name: '',
        last_name: '',
        phone: '',
        email: '',
        country: '',
        change_password: false,
        old_password: '',
        password: '',
        password_confirmation: '',
      },
      vueTel: {
        phone: '',
        props: {
          maxLen: 11,
          validCharactersOnly: true,
          mode: "international",
          dynamicPlaceholder: 'true',
          autoDefaultCountry: false,
          disabled: false,
          required: true,
          enabledCountryCode: false,
          enabledFlags: true,
          onlyCountries: [],
          ignoredCountries: [],
          autocomplete: "off",
          name: "telephone",
          inputClasses: "",
          inputOptions: {
            showDialCode: true,
          },
          disabledFormatting: true,
          wrapperClasses: "input-row"
        }
      },
      validationErrors: {},
    }
  },
  validations: {
    payload: {
      first_name: {
        required,
        maxLength: maxLength(25),
        minLength: minLength(2),
      },
      last_name: {
        required,
        maxLength: maxLength(25),
        minLength: minLength(2)
      },
      phone: {
        required,
        maxLength: maxLength(18),
        minLength: minLength(7),
      },
      email: {
        email,
        maxLength: maxLength(30),
        required,
      },
      old_password: {
        maxLength: maxLength(25),
        minLength: minLength(8),
      },
      password: {
        maxLength: maxLength(25),
        minLength: minLength(8),
      },
      password_confirmation: {
        maxLength: maxLength(25),
        minLength: minLength(8),
        sameAsPassword: sameAs('password')
      },
    },
  },
  created() {
    this.fetchUserData().then(()=>{
        this.setUserData()
    })
  },
  computed:{
    ...mapGetters({
      userData: 'profile/user',
    }),
    firstNameError() {
      const error = []
      if (!this.$v.payload.first_name.$dirty) {
        return error
      }
      if (!this.$v.payload.first_name.required) {
        error.push(this.$t('errorRequired'))
      }
      if (!this.$v.payload.first_name.maxLength) {
        error.push(this.$t('errorMaxLength').replace(':count', 25))
      }
      if (!this.$v.payload.first_name.minLength) {
        error.push(this.$t('errorMinLength').replace(':count', 2))
      }
      if (this.validationErrors.first_name) {
        this.validationErrors.first_name.forEach((row) => {
          error.push(row)
        })
        this.validationErrors = {}
      }
      return error
    },
    lastNameError() {
      const error = []
      if (!this.$v.payload.last_name.$dirty) {
        return error
      }
      if (!this.$v.payload.last_name.required) {
        error.push(this.$t('errorRequired'))
      }
      if (!this.$v.payload.last_name.maxLength) {
        error.push(this.$t('errorMaxLength').replace(':count', 25))
      }
      if (!this.$v.payload.last_name.minLength) {
        error.push(this.$t('errorMinLength').replace(':count', 2))
      }
      if (this.validationErrors.last_name) {
        this.validationErrors.last_name.forEach((row) => {
          error.push(row)
        })
        this.validationErrors = {}
      }
      return error
    },
    phoneError() {
      const error = []
      if (!this.$v.payload.phone.$dirty) {
        return error
      }
      if (!this.$v.payload.phone.required) {
        error.push(this.$t('errorRequired'))
      }
      if (!this.$v.payload.phone.maxLength) {
        error.push(this.$t('errorMaxLength').replace(':count', 15))
      }
      if (!this.$v.payload.phone.minLength) {
        error.push(this.$t('errorMinLength').replace(':count', 7))
      }
      if (this.validationErrors.phone) {
        this.validationErrors.phone.forEach((row) => {
          error.push(row)
        })
        this.validationErrors = {}
      }
      return error
    },
    emailError() {
      const error = []
      if (!this.$v.payload.email.$dirty) {
        return error
      }
      if (!this.$v.payload.email.required) {
        error.push(this.$t('errorRequired'))
      }
      if (!this.$v.payload.email.email) {
        error.push(this.$t('errorEmail'))
      }
      if (!this.$v.payload.email.maxLength) {
        error.push(this.$t('errorMaxLength').replace(':count', 30))
      }
      if (this.validationErrors.email) {
        this.validationErrors.email.forEach((row) => {
          error.push(row)
        })
        this.validationErrors = {}
      }
      return error
    },
    oldPasswordError() {
      const error = []
      if (!this.$v.payload.password.$dirty) {
        return error
      }
      if (!this.$v.payload.password.maxLength) {
        error.push(this.$t('errorMaxLength').replace(':count', 25))
      }
      if (!this.$v.payload.password.minLength) {
        error.push(this.$t('errorMinLength').replace(':count', 8))
      }
      if (this.validationErrors.password) {
        this.validationErrors.password.forEach((row) => {
          error.push(row)
        })
        this.validationErrors = {}
      }
      return error
    },
    passwordError() {
      const error = []
      if (!this.$v.payload.password.$dirty) {
        return error
      }
      if (!this.$v.payload.password.maxLength) {
        error.push(this.$t('errorMaxLength').replace(':count', 25))
      }
      if (!this.$v.payload.password.minLength) {
        error.push(this.$t('errorMinLength').replace(':count', 8))
      }
      if (this.validationErrors.password) {
        this.validationErrors.password.forEach((row) => {
          error.push(row)
        })
        this.validationErrors = {}
      }
      return error
    },
    confirmPasswordError() {
      let error = [];
      if (!this.$v.payload.password_confirmation.$dirty) {
        return error;
      }
      if (!this.$v.payload.password_confirmation.sameAsPassword) {
        error.push(this.$t('validationSameAs'));
      }
      if (this.validationErrors.password_confirmation) {
        this.validationErrors.password_confirmation.forEach((row) => {
          error.push(row);
        })
      }
      return error;
    },
  },

  methods:{
    ...mapMutations({
    }),
    ...mapActions({
      fetchUserData: `profile/FETCH_USER_DATA`,
      updateUserData: `profile/UPDATE_USER_DATA`
    }),
    setUserData(){
      this.payload.first_name = this.userData.firstName
      this.payload.last_name = this.userData.lastName
      this.payload.phone = this.userData.phone
      this.payload.email = this.userData.email
      this.userData.country === null ? this.payload.country = '' : this.payload.country = this.userData.country
    },
    submitForm() {
      this.$v.$touch();
      if (!this.$v.payload.$invalid) {
        console.log(this.payload);
        (this.payload.old_password === '' && this.payload.password === '' && this.payload.password_confirmation === '') ? this.payload.change_password = false : this.payload.change_password = true
        this.updateUserData(this.payload).then((result) => {
          this.$toasted.success(this.$t('successUpdateInfo'));
          this.fetchUserData()
          this.payload.old_password = ''
          this.payload.password = ''
          this.payload.password_confirmation = ''
        }).catch(error => {
          if (error.status === 422) {
            this.$toasted.error(error.data.message);
            const errors = error.data.errors;
            for(const i in errors){
              errors[i].forEach(e => {
                this.$toasted.error(e);
              })
            }
          }
        })
      }
    }
  }
}
